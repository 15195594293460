<template>
  <!-- 简历弹窗 -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="800px"
    append-to-body
    title="查看简历"
  >
    <el-form :model="form" ref="ruleForm" label-width="150px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            {{ form.name }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            {{ form.email }}
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="所学专业" prop="specialty">
        {{ form.specialty && form.specialty.name }}
      </el-form-item>

      <el-form-item label="毕业院校" prop="school">
        <div
          class="relativeInput"
          v-for="(school, index) in form.school"
          :key="index"
        >
          {{ form.school[index].name }}/{{
            form.school[index].qualification == 0
              ? "本科"
              : form.school[index].qualification == 1
              ? "硕士"
              : "博士"
          }}
        </div>
      </el-form-item>

      <el-form-item label="曾经公司" prop="originalCompany">
        <div
          class="relativeInput"
          v-for="(company, index) in form.company"
          :key="index"
        >
          {{ company }}
        </div>
      </el-form-item>
      <el-form-item label="项目经验" prop="project">
        <div
          v-for="(project, index) in form.project"
          class="relativeInput"
          :key="index"
        >
          <div style="margin-bottom: 6px">{{ project.title }}</div>
          <div>{{ project.content }}</div>
        </div>
      </el-form-item>

      <el-row>
        <el-col :span="12">
          <el-form-item label="工作经验" prop="workExperience">
            {{ form.workExperience && form.workExperience.name }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="刷题量" prop="questions">
            {{ form.questions }}
            <el-button
              style="margin-left: 12px"
              size="mini"
              @click="openlcScreen"
              >查看截图</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="面试岗位" prop="post">
            {{ form.post && form.post.name }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="求职目标" prop="target">
            {{ form.target && form.target.name }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="第三人称简介" prop="briefIntroduction">
        {{ form.briefIntroduction }}
      </el-form-item>
      <el-form-item label="自我介绍" prop="selfIntroduction">
        {{ form.selfIntroduction }}
      </el-form-item>
    </el-form>
    <el-image-viewer
      v-if="showViewer"
      :url-list="[form.lcPrintScreen]"
      :on-close="closeViewer"
      :z-index="3000"
    />
  </el-dialog>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getPersonalCv } from "@/service/interpolation";
export default {
  name: "cvDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    personId: {
      type: [String, Number],
      default: "",
    },
  },
  components: { ElImageViewer },
  data() {
    return {
      form: {},
      showViewer: false,
    };
  },
  mounted() {},
  methods: {
    getCv() {
      getPersonalCv(this.personId).then((res) => {
        if (res.success) {
          this.form = res.result;
        }
      });
    },
    openlcScreen() {
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getCv();
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeDialogVisible", val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-image-viewer__wrapper {
  z-index: 3000 !important;
}
::v-deep .el-table__row {
  cursor: pointer;
}
</style>