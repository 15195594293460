import { render, staticRenderFns } from "./history.vue?vue&type=template&id=c209166a&scoped=true"
import script from "./history.vue?vue&type=script&lang=js"
export * from "./history.vue?vue&type=script&lang=js"
import style0 from "./history.vue?vue&type=style&index=0&id=c209166a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c209166a",
  null
  
)

export default component.exports