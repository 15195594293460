import request from '../utils/request';


// 获取内推人注册详情
export async function getReferRegisterDetail() {
    try {
        return await request({
            url: `/referEmployee`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 保存内推人详情
export async function saveReferRegister(data) {
    try {
        return await request({
            url: `/referEmployee`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 发送验证码
export async function sendVerifyEmail(email) {
    try {
        return await request({
            url: `/code/sendVerificationCode?phoneOrEmail=${email}`,
            method: 'get',

        });
    } catch (error) {
        return {};
    }
}
// 获取认证列表
export async function getAccessList(params) {
    try {
        return await request({
            url: `/referEmployee/referCandidateList`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取我的求职者列表
export async function getMyReferList(params) {
    try {
        return await request({
            url: `/referEmployee/myReferCandidate`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取历史求职者列表
export async function getHistoryReferList(params) {
    try {
        return await request({
            url: `/referEmployee/history`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 邀请用户
export async function inviteUser(id) {
    try {
        return await request({
            url: `/referEmployee/refer/${id}`,
            method: 'put',
        });
    } catch (error) {
        return {};
    }
}
// 下载简历
export async function downloadPDF(id) {
    try {
        return await request({
            url: `/referEmployee/download/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}