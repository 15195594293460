// 内推相关
import request from "../utils/request";

// 获取内推列表
export async function getInterpolationList(params) {
    try {
        return await request({
            url: "/referCandidate/refer",
            method: "get",
            params,
        });
    } catch (error) {
        return {};
    }
}
// 提交内推信息
export async function accessInterpolation(data) {
    try {
        return await request({
            url: "/referCandidate/send",
            method: "post",
            data,
        });
    } catch (error) {
        return {};
    }
}

// 邀请内推
export async function acceptInvite(data, id) {
    try {
        return await request({
            url: `/referCandidate/accept/${id}`,
            method: "put",
            data,
        });
    } catch (error) {
        return {};
    }
}
// 获取个人简历
export async function getPersonalCv(id) {
    try {
        return await request({
            url: `/referEmployee/referCandidateDetail/${id}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}
// 获取沟通历史记录
export async function getComList(id, params) {
    try {
        return await request({
            url: `/referExchange/${id}`,
            method: "get",
            params
        });
    } catch (error) {
        return {};
    }
}
// 发送消息
export async function sendReply(data) {
    try {
        return await request({
            url: `/referExchange`,
            method: "post",
            data
        });
    } catch (error) {
        return {};
    }
}